<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <!-- class="mr-3 hidden-xs-only" -->
      <base-img
        :src="require('@/assets/logo-horizontal.png')"
        class="mr-3"
        contain
        max-width="240"
        width="100%"
      />

      <!-- <base-img
        :src="require(`@/assets/zero-logo-${$vuetify.theme.isDark ? 'dark' : 'light'}.svg`)"
        contain
        max-width="96"
        width="100%"
      /> -->

      <!-- <span
        class="text-h4 font-italic"
      >
        Position++
      </span> -->

      <v-spacer />

      <!-- <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :exact="item.text === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </div> -->

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="toggleDrawer"
      />

      <v-btn
        v-for="(section, i) in sections"
        :key="i"
        :to="section.to"
        :href="section.href"
        v-bind="section"
        class="hidden-sm-and-down font-weight-bold black--text"
        text
        @click="onClick($event, section)"
      >
        {{ section.text }}
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapMutations,
  } from 'vuex'

  export default {
    name: 'HomeAppBar',

    data: () => ({
    }),

    computed: {
      ...mapGetters(['sections']),
    },

    methods: {
      ...mapMutations(['toggleDrawer']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
